<template>
  <div id="Layout" :class="{ expanded: expanded }">
    <nav>
      <VerticalNavigation></VerticalNavigation>
    </nav>
    <section>
      <div>
        <!-- Home Drawer -->
        <perfect-scrollbar id="Home">
          <div>
            <WidgetsWrapper></WidgetsWrapper>
            <div>
              <div id="SecurityPanel" v-if="stateReady">
                <SecurityFab></SecurityFab>
                <v-btn
                  dark
                  small
                  fab
                  color="blue darken-2"
                  :loading="$store.state.requesting > 0"
                  @click="requestDeviceStates"
                >
                  <i class="fa fa-fw fa-sync-alt fa-2x" />
                </v-btn>
              </div>
              <GCMComponent />
              <Scenes
                v-if="stateReady"
                id="FavoriteScenes"
                :favorites="true"
                :limit="4"
              ></Scenes>
              <ConnectionState v-model="stateReady" />
            </div>
          </div>
          <div>
            <Devices id="FavoriteDevices" :favorites="true"></Devices>
          </div>
          <div class="marginer"></div>
        </perfect-scrollbar>

        <!-- Rooms Drawer -->
        <perfect-scrollbar id="RoomsDrawer">
          <Rooms></Rooms>
          <div class="marginer"></div>
        </perfect-scrollbar>
      </div>
    </section>
  </div>
</template>

<script>
import VerticalNavigation from "@/components/VerticalNavigation";
import WidgetsWrapper from "@/components/WidgetsWrapper";
import Devices from "@/components/Devices";
import Scenes from "@/components/Scenes";
import Rooms from "@/components/Rooms";
import SecurityFab from "@/components/SecurityFab";
import ConnectionState from "@/components/ConnectionState";
import GCMComponent from "@/components/GCMComponent";

export default {
  name: "Home",
  components: {
    VerticalNavigation,
    Devices,
    WidgetsWrapper,
    Scenes,
    Rooms,
    SecurityFab,
    ConnectionState,
    GCMComponent,
  },
  data() {
    return {
      stateReady: false,
      requesting: false,
    };
  },
  computed: {
    expanded() {
      return this.$route.path === "/rooms";
    },
  },
  mounted() {
    //
  },
  destroyed() {
    //
  },
  methods: {
    expand() {
      const lay = document.getElementById("Layout");
      if (!lay.classList.contains("expanded")) lay.classList.add("expanded");
      else lay.classList.remove("expanded");
    },
    requestDeviceStates() {
      this.$set(this, "requesting", true);
      this.$store.state.socketApi
        .getDeviceStatesByRoomId(0x00, false)
        .finally(() => this.$set(this, "requesting", false));
    },
    feedbackCatcher($event) {
      console.log("feedbackCatcher", $event);
    }
  },
};
</script>

<style lang="scss">
@import "~vuetify/src/styles/settings/_variables.scss";
@import "~vuetify/src/components/VCard/_variables.scss";

$background-image: url("../assets/backgrounds/interior-has-armchair-empty-white-wall.jpg");
$nav-drawer-width: 4.5rem;
$frame-width: 0.5rem;

$drawer-transition-duration: 0.5s;
$drawer-background-transition-duration: #{$drawer-transition-duration / 2};
$drawer-background-transition-delay: #{$drawer-transition-duration / 2};
$drawer-transform-transition-duration: #{$drawer-transition-duration / 2};
$drawer-transform-transition-delay: 0;
$drawer-sliding-transition-duration: #{$drawer-transition-duration / 2};
$drawer-sliding-transition-delay: #{$drawer-transition-duration / 2};

.ps {
  min-width: 50%;
  max-height: 100%;
}

#Layout {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-self: stretch;
  height: 100vh;
  padding: $frame-width;

  & > nav {
    display: flex;
    width: $nav-drawer-width;
  }

  & > section {
    flex: 1;

    margin-left: $frame-width;
    overflow: hidden;

    border-radius: $card-border-radius;
    background-color: #ccc;

    background-image: $background-image;
    background-position: 0 center;
    background-repeat: no-repeat;
    background-size: cover;

    transition: background-position 500ms linear 500ms;

    & > div {
      display: flex;
      flex-direction: row;
      width: 200%;
      max-height: 100%;
      overflow: hidden;
      margin-left: 0;

      transition: margin 300ms ease-out 300ms;
      /* transition-delay: 0; */

      & > div {
        flex: 1;
        overflow: hidden;
      }
    }
  }

  #Home {
    transition: transform 200ms ease-out 300ms, opacity 200ms ease-out 300ms;

    & > div:first-of-type {
      min-height: 40vh;
    }

    & > div {
      display: flex;
      flex-direction: row;
      flex: 1;
      justify-content: flex-end;

      & > div {
        flex: 1;
        align-self: center;
      }
    }
  }

  #RoomsDrawer {
    /* padding-left: 20vw; */
  }

  &.expanded {
    & > section {
      /* transition: background-position 300ms linear 300ms; */
      background-position: 100% center;

      & > div {
        transition: margin 200ms ease-out 500ms;
        margin-left: -100%;
      }
    }

    #Home {
      transition: transform 200ms ease-out 0, opacity 200ms ease-out 0;
      /* transform: scale(0.9); */
      opacity: 0;
    }
  }

  #WidgetsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

#FavoriteDevices,
#SecurityPanel,
#FavoriteScenes > div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: stretch;
}

#SecurityPanel {
  padding: 1rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding-right: 2rem;

  button.v-btn.v-btn--contained {
    padding: 0;
    min-width: auto;
    min-height: auto;
  }

  button.v-btn.v-btn--contained:not(.v-size--small) {
    width: 3.5rem;
    height: 3.5rem;
  }

  button.v-btn.v-btn--contained.v-size--small {
    width: 3rem;
    height: 3rem;
  }
}

#FavoriteScenes {
  flex-wrap: wrap;

  .scene-card {
    width: 35%;
  }

  .scene-card .v-card__text {
    color: #fff !important;
  }
}

div.marginer {
  display: none;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  #Layout {
    section {
      margin: 0;

      & > div > div {
        margin: 0;
        /* padding: 0 0 20vh 0; */
        padding: 0 0 0 0;
        max-height: none;
      }
    }

    #Home {
      & > div {
        flex-direction: column;

        & > div {
          align-self: flex-end;
          width: 100%;
        }
      }
    }

    #RoomsDrawer {
      padding-left: 0;
    }

    #FavoriteScenes {
      .scene-card {
        flex: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    #WidgetsWrapper {
      margin-top: 1rem;
    }

    div.marginer {
      display: block !important;
      min-height: 20vh;

      &::after {
        content: " ";
      }
    }
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} and (orientation: portrait) {
  #Layout {
    flex-direction: column-reverse;

    section > div > div {
      min-width: calc(100vw - #{$frame-width * 2});
    }

    nav {
      position: fixed;
      left: 0;
      bottom: 0;
      width: calc(100vw - #{$frame-width * 2});
      margin: 0 $frame-width $frame-width $frame-width;
      z-index: 3;
    }
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} and (orientation: landscape) {
  #Layout {
    section > div > div {
      min-width: calc(100vw - #{($frame-width * 2) + $nav-drawer-width});
    }

    #Home > div > div,
    #FovoriteScenes {
      width: 100%;

      .scene-card {
        width: 20vw;
      }
    }
  }
}

@media #{map-get($display-breakpoints, 'xl-only')} and (orientation: landscape) {
  #FavoriteScenes {
    .scene-card {
      width: 10vw;
    }
  }
}

@media #{map-get($display-breakpoints, 'sm-only')} and (orientation: portrait) {
  #FavoriteScenes .scene-card {
    width: 40% !important;
  }
}

@media #{map-get($display-breakpoints, 'sm-only')} and (orientation: landscape) {
  #FavoriteScenes .scene-card {
    width: 44% !important;
    max-width: 256px;
  }
}
</style>
