<template>
  <v-speed-dial id="SecurityDial"
                v-model="fab"
                direction="left">
    <template v-slot:activator>
      <v-btn v-model="fabState"
             :color="activeModeColor"
             dark fab>
        <!-- <v-icon v-if="fabState"> fas fa-fw fa-times </v-icon> -->
        <v-icon class="px-2 py-4" style="width:56px;height:56px;"
                v-if="inProgress || !activeMode">fas fa-fw fa-cog fa-spin</v-icon>
        <img v-else
             :src="activeModeIcon" class="pa-1" />
      </v-btn>
    </template>
    <v-btn dark
           small fab
           v-for="(mode, key) in modes"
           :active="key === activeMode"
           :key="key"
           :color="mode && mode.color"
           @click="setMode(mode.id)" class="pa-1">
      <img :src="mode.icon" />
    </v-btn>
  </v-speed-dial>
</template>

<script>
export default {
  name: "SecurityFab",
  components: {},
  data() {
    return {
      fab: false,
      inProgress: false,
      modes: {
        disarmed: {
          id: 3,
          icon: require("@/assets/icons/security-disarmed.svg"),
          color: "orange",
        },
        sleep: {
          id: 4,
          icon: require("@/assets/icons/security-sleep.svg"),
          color: "green",
        },
        custom: {
          id: 5,
          icon: require("@/assets/icons/security-custom.svg"),
          color: "yellow darken-2",
        },
        armed: {
          id: 2,
          icon: require("@/assets/icons/security-armed.svg"),
          color: "blue",
        },
      },
    };
  },
  computed: {
    fabState: {
      get() {
        return !this.inProgress && this.fab;
      },
      set(value) {
        if (this.inProgress) this.fab = false;
        else this.fab = value;
      },
    },
    activeMode() {
      return (
        this.$store &&
        this.$store.state.security &&
        this.$store.state.security.mode_name
      );
    },
    activeModeIcon() {
      return this.activeMode && this.modes[this.activeMode].icon;
    },
    activeModeColor() {
      if (!this.activeMode) return "grey";
      else return this.activeMode && this.modes[this.activeMode].color;
    },
  },
  methods: {
    requestMode() {
      this.$store.state.socketApi
        .securityRequest()
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
    },
    setMode(mode) {
      if (this.inProgress) return;
      this.inProgress = true;
      this.$store.state.socketApi
        .securityMode(mode)
        .then((res) => {
          this.$analytics.logEvent("action", {security:this.activeMode});
          console.log(res);
        })
        .catch((err) => console.log(err))
        .finally(() => (this.inProgress = false));
    },
  },
};
</script>

<style>
#SecurityDial button[active="true"] {
  display: none;
}

#SecurityDial button[disabled] {
  pointer-events: none;
}

#SecurityDial button {
  padding: 0;
  height: auto !important;
}

#SecurityDial button img {
  max-width: 100%;
}
</style>